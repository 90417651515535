export enum RequestServiceInMemoryStoreNames {
  STORE_COLLECTIVE_AGREEMENTS = 'STORE_COLLECTIVE_AGREEMENTS',
  STORE_COUNTRIES = 'STORE_COUNTRIES',
  STORE_EMPLOYMENTS = 'STORE_EMPLOYMENTS',
  STORE_INSURANCES = 'STORE_INSURANCES',
  STORE_MEMBERSHIP = 'STORE_MEMBERSHIP',
  STORE_PHONE_COUNTRY_CODES = 'STORE_PHONE_COUNTRY_CODES',
  STORE_POSTAL_CODES = 'STORE_POSTAL_CODES',
  STORE_RESERVATIONS = 'STORE_RESERVATIONS',
  STORE_SCHOLARSHIP_APPLICATIONS = 'STORE_SCHOLARSHIP_APPLICATIONS',
  STORE_SHOP_STEWARDS_RELEVANT_TO_USER = 'STORE_SHOP_STEWARDS_RELEVANT_TO_USER',
  STORE_SUBSCRIPTIONS = 'STORE_SUBSCRIPTIONS',
}
